/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and UI's base styles.
/* ----------------------------------------------------------------------------------------------------- */
/* width */

body.light *::-webkit-scrollbar {
  width: 6px;
}

/* TODO: Need to do this with tailwind class */

/* Track */
body.light *::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
body.light ::-webkit-scrollbar-thumb {
  background: var(--ui-primary-500);
  border-radius: 10px;
}

/* Handle on hover */
body.light ::-webkit-scrollbar-thumb:hover {
  background: var(--ui-warn-700);
}

body.light {
  .error-snackbar {
    background: var(--ui-warn-500);
    color: white;
  }
  .mat-simple-snackbar-action {
    color: white;
  }
}
body.dark {
  .error-snackbar {
    background: var(--ui-warn-500);
    color: white;
  }
}

/* TODO: Need to do this with tailwind class */

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ui-primary-500);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ui-warn-700);
}
